
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  nextTick,
  getCurrentInstance,
  watchEffect,
  reactive
} from "vue";
import { useStore } from "vuex";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import CustomerService from "@/core/services/car/CustomerService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import UserService from "@/core/services/UserService";
import MasterService from "@/core/services/car/MasterService";
import {
  notif,
  yearList,
  getCurrentDate,
  validation,
  carBikeValueCheck,
  otherGenders
} from "@/store/stateless/store";
import { ElNotification } from "element-plus";
import ExistCustomer from "@/components/modals/forms/ExistCustomer.vue";
import { setBodyPositionFixed } from "@/store/stateless/store";
import Validations from '@/core/services/etc/Validations';
import router from "@/router";
import moment from "moment";
import { registration } from '@/store/stateless/store'

interface addDetailUserParam {
  name: string;
  email: string;
  phone_country_code: string;
  phone_number: string;
  car_year: any | null;
  manufacturer_id: any | null;
  model_id: any | null;
  trim_level_id: any | null;
  is_new: number | null;
  car_value: number;
  first_registration_date: string;
  registration_emirate: number | any;
  dob: string | null;
  nationality: number | null;
  first_driving_license_country: number | null;
  driving_experience: any | null;
  uae_driving_experience: any | null;
  policy_start_date: string | null;
  claimed_insurance: number;
  no_claim_certificate: number | null;
  claims: number | null;
  gcc_specification: number | null;
  personal_use: number | null;
  current_policy_active: number | null;
  fully_comprehensive: number | null;
  third_party_liability: number | null;
  existing_policy_expired: number | null;
  driver_name: string;
  agent: string;
  gender: any | null;
  is_car: number;
  lead_source: string;
  is_vintage: number;
  vehicle_type: number;
}
export default defineComponent({
  name: "customer-add-detail",
  props: {},
  components: {
    ExistCustomer,
  },

  data() {
    return {
      dob: "",
      policyBegin: "",
      registrationDate: "",
    };
  },
  created() {
    const datenow = new Date();
    const day = datenow.getDate();
    const month = datenow.getUTCMonth();
    const year = datenow.getFullYear() - 18;
    const yearMinuseighteen = new Date(year, month, day);
    console.log(yearMinuseighteen);
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance();
    let name = ref<string>("");
    let email = ref<string>("");

    let phone_country_code = ref<string>("");

    let addDetailUserParam = ref<addDetailUserParam>({
      name: "",
      email: "",
      phone_country_code: "+961",
      phone_number: "",
      car_year: 0,
      manufacturer_id: 0,
      model_id: 0,
      trim_level_id: 0,
      is_new: 2,
      car_value: 0,
      first_registration_date: "",
      registration_emirate: 7,
      dob: null,
      nationality: null,
      first_driving_license_country: null,
      driving_experience: "4",
      uae_driving_experience: "4",
      policy_start_date: getCurrentDate.today(),
      claimed_insurance: 2,
      no_claim_certificate: 1,
      claims: null,
      gcc_specification: 1,
      personal_use: 1,
      current_policy_active: 1,
      fully_comprehensive: 2,
      third_party_liability: 1,
      existing_policy_expired: 2,
      driver_name: "",
      agent: "",
      gender: null,
      is_car: 1,
      lead_source: "skye",
      is_vintage: 0,
      vehicle_type:2,
    });
    // console.log(route.params);

    let nameRef = ref<any>("");
    let phoneRef = ref<any>("");
    let yearRef = ref<any>("");
    let DobRef = ref<any>("");
    nameRef.value = document.querySelector("#nameRef");
    phoneRef.value = document.querySelector("#phoneRef");
    yearRef.value = document.querySelector("#yearRef");
    
    DobRef.value = document.querySelector("#DobRef");

    const years = yearList.getYearList();

    let manufacturerList = computed(() => {
      let manu = store.getters.getBikeManufacturersList;
      const exist = manu.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        manu.unshift({
          label: "Car Make",
          value: 0,
          id: 0,
          manufacturer: "Make",
        });
      } else {
        manu = manu.forEach((x) => {
          if (x.manufacturer != "QL") {
            return { ...x };
          }
        });
      }

      return manu;
    });
    
    let modelList = computed(() => {
      const model = store.getters.getModelsList;
      const exist = model.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        model.unshift({ label: "Car Model", value: 0, id: 0, model: "Model" });
      }
      return model;
    });

    const estimatedCarValue = computed(() => {
      return store.getters.getEstimatedCarValue
    })

    const getBrandNew = () => {
      addDetailUserParam.value.car_value = addDetailUserParam.value.is_new === 1 ? estimatedCarValue.value.new_min : estimatedCarValue.value.old_min;
    }
   
    let trimsList = computed(() => {
      const trim = store.getters.getTrimDetails;
      const exist = trim.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        trim.unshift({ label: "Trim", value: 0, id: 0, model: "Trim" });
      }
      return trim;
    });
    
    let countryList = computed(() => {
      return store.getters.getCountry;
    });
    
    let dlCountryList = computed(() => {
      return store.getters.getDlCountry;
    });
    
    const emirates = ref([
      { value: 0, label: "Abu Dhabi" },
      { value: 1, label: "Ajman" },
      { value: 2, label: "Dubai" },
      { value: 3, label: "Fujairah" },
      { value: 4, label: "Ras Al Khaimah" },
      { value: 5, label: "Sharjah" },
      { value: 6, label: "Umm Al Quwain" },
    ]);
  
    const uaeExperience = ref([
      { value: "1", label: "0-6 months" },
      { value: "2", label: "6 - 12 months" },
      { value: "3", label: "1 - 2 Years" },
      { value: "4", label: "More than 2 Years" },
    ]);
    const homeExperience = ref([
      { value: "1", label: "0-6 months" },
      { value: "2", label: "6 - 12 months" },
      { value: "3", label: "1 - 2 Years" },
      { value: "4", label: "More than 2 Years" },
    ]);
    const matchFirstDL = (e) => {
      addDetailUserParam.value.first_driving_license_country = e;
    };

    
    const ncc = ref([
      { value: 1, label: "No - I have no certificate" },
      { value: 2, label: "Yes - 1 year with proof" },
      { value: 3, label: "Yes - 2 years with proof" },
      { value: 4, label: "Yes - 3 or more years with proof" },
    ]);
    let salesAgentList = computed(() => {
      return store.getters.getDomSalesAgentManagerTeamLeader;
    });
    

    const user = computed(() => {
      return JSON.parse(window.localStorage.getItem('user') || '')
    })

    onMounted(() => {
      getCarYears()
      setTimeout(() => {
        const nameRef:any = inst?.refs.nameRef
        nameRef.focus()
      }, 500);
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Add Lead - Bike Details", ["Leads & Tasks"]);

      QuotesService.getCountries();
      UserService.getDomSalesAgentsManagerTeamLeader();
      MasterService.getCountryCodes();
      MasterService.getPolicyStartDateDays();
      Promise.all([
        CustomerService.getCustomerDetails(store, route.params.customerId),
      ]).then((data) => {
        const obj = store.getters.getSelectedCustomerDetails;
        addDetailUserParam.value.name = obj.name;
        addDetailUserParam.value.email = obj.email;
        addDetailUserParam.value.phone_country_code = obj.phone_country_code;
        addDetailUserParam.value.phone_number = obj.phone_number_without_code;
        addDetailUserParam.value.agent = obj.agent;
        addDetailUserParam.value.driver_name = obj.name;
        console.log("Customer",obj);
      });
      
    });
    const errors = computed(() => {
      return store.state.CustomerModule.errors;
    });

    const yearsList = computed(() => {

      let defaultValue = [{ label: 'Year', value: 0 }]
      
      const carYears = store.getters.getCarYears
      if(carYears.length > 0) {
        defaultValue = carYears.map(x => {
          return {
            label: x,
            value: x
          }
        })

        const exist = defaultValue.some(x => x.value === 0)
        if(!exist) defaultValue.unshift({ label: 'Year', value: 0 })

        return defaultValue
      }

      return defaultValue
    })

    const getCarYears = async () => {
      await MasterService.getCarYears({is_vintage: addDetailUserParam.value.is_vintage})
      addDetailUserParam.value.car_year = 0
      addDetailUserParam.value.manufacturer_id = 0
      addDetailUserParam.value.model_id = 0
      addDetailUserParam.value.trim_level_id = 0
      addDetailUserParam.value.car_value = 0
      addDetailUserParam.value.first_registration_date = ''

      QuotesService.resetValues()
    }

    // change event
    const onYearSelect = async (event) => {
      
      QuotesService.resetValues();
      addDetailUserParam.value.car_value = 0;
      addDetailUserParam.value.manufacturer_id = 0;
      addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = 0;
      // if(!yearList.getYearValue().includes(addDetailUserParam.value.car_year)) {
        addDetailUserParam.value.is_new = 2;
        addDetailUserParam.value.first_registration_date = '';
      // }
      // addDetailUserParam.value.car_year = event;
      if(!addDetailUserParam.value.is_vintage) {
        addDetailUserParam.value.first_registration_date = firstRegistrationDate(event)
      }
      await QuotesService.getBikeManufacturers({
        year: addDetailUserParam.value.car_year,
        is_vintage: addDetailUserParam.value.is_vintage
      });
      console.log(manufacturerList.value);
      const manu = manufacturerList.value.filter((i) => i.value != 0);
      addDetailUserParam.value.manufacturer_id = manu[0]?.make_id ?? 0;
      addDetailUserParam.value.model_id =  manu[0]?.model_id ?? 0;
      console.log(addDetailUserParam.value);
      onModelChange()
      removeError()
    };

    const firstRegistrationDate = (carYear) => {
      const currentDate = new Date()
      const cMonth = currentDate.getMonth() + 1
      const cDay = currentDate.getDate()
      const mMonth = cMonth.toString().length === 1 ? `0${cMonth}` : cMonth
      const mDay = cDay.toString().length === 1 ? `0${cDay}` : cDay
      // return `${mDay}/${mMonth}/${carYear}`
      return `${carYear}-${mMonth}-${mDay}`
    }

    const onManufacturerChange = () => {
      // addDetailUserParam.value.manufacturer_id = event;
      QuotesService.resetModelValues();
      addDetailUserParam.value.car_value = 0;
      // addDetailUserParam.value.manufacturer_id = 0;
      addDetailUserParam.value.model_id = 670;
      addDetailUserParam.value.trim_level_id = 0;
      QuotesService.getModels({
        manufacturer_id: addDetailUserParam.value.manufacturer_id,
        year: addDetailUserParam.value.car_year,
        is_vintage: addDetailUserParam.value.is_vintage
      });
      // models.value.value = null;
      // trims.value.value = null;
      addDetailUserParam.value.car_value = 0;
      // onModelChange()
    };
    const onModelChange = () => {
      addDetailUserParam.value.car_value = 0;
      // addDetailUserParam.value.manufacturer_id = 0;
      // addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = 0;
      QuotesService.resetTrimValues();
      // addDetailUserParam.value.model_id = event;
      QuotesService.getTrims({
        year: addDetailUserParam.value.car_year,
        model_id: addDetailUserParam.value.model_id,
        is_vintage: addDetailUserParam.value.is_vintage
      });
      // trims.value.value = null;
      addDetailUserParam.value.car_value = 0;
    };
    const onTrimChange = async (event) => {
      // addDetailUserParam.value.trim_level_id = event;
      QuotesService.getEstimatedValue({
        trim_level_id: addDetailUserParam.value.trim_level_id,
        is_vintage: addDetailUserParam.value.is_vintage
      }).then((result: any) => {
        // addDetailUserParam.value.car_value = result.data
        //   ? parseFloat(result.data.value.old_min)
        //   : 0;
        removeError()
      });
    };

    const removeError = () => {
          const rules:any = inst?.refs.ruleForm;
          rules.validate(async (valid, field: any) => {
                    // rules.scrollToField(Object.keys(field)[0])
            })
    }

    const getPolicyExpiry = (event) => {
      addDetailUserParam.value.existing_policy_expired = parseInt(
        event.target.value
      );
      addDetailUserParam.value.current_policy_active =
        event.target.value == 2 ? 1 : 2;
    };

    

    const reset = () => {
      // manufacturers.value.value = null;
      // models.value.value = null;
      // trims.value.value = null;
      addDetailUserParam.value.car_value = 0;
      addDetailUserParam.value.manufacturer_id = 0;
      addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = 0;
      addDetailUserParam.value.car_year = 0;
    };

    const carValue = ref<string>("");
    const noOfClaims = ref<string>("");
    const loading = ref<boolean>(false);

    const validationRules = reactive({
        // name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
        // email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
        // phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
        car_year: [{ validator: Validations.car_year, trigger: ['blur', 'change'] }],
        trim_level_id: [{ validator: Validations.bike_trim, trigger: ['blur', 'change'] }],
        car_value: [{ validator: Validations.car_value, trigger: ['blur', 'change'] }],
        registration_emirate: [{ validator: Validations.registration_emirate, trigger: ['blur', 'change'] }],
        first_registration_date: [{ validator: Validations.first_registration_date, trigger: ['blur', 'change'] }],
        policy_start_date: [{ validator: Validations.policy_start_date, trigger: ['blur', 'change'] }],
        dob: [{ validator: Validations.dob, trigger: ['blur', 'change'] }],
        nationality: [{ validator: Validations.nationality, trigger: ['blur', 'change'] }],
        first_driving_license_country: [{ validator: Validations.first_driving_license_country, trigger: ['blur', 'change'] }],
        driving_experience: [{ validator: Validations.driving_experience, trigger: ['blur', 'change'] }],
        uae_driving_experience: [{ validator: Validations.uae_driving_experience, trigger: ['blur', 'change'] }],
        no_of_claims: [{ validator: Validations.no_of_claims, trigger: ['blur', 'change'] }],
        no_claim_certificate: [{ validator: Validations.no_claim_certificate, trigger: ['blur', 'change'] }],
        gender: [{ validator: Validations.gender, trigger: ['blur', 'change'] }],
        driver_name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
        claimed_insurance: [{ validator: Validations.is_claimed_insurance, trigger: ['blur', 'change'] }],
    })

    const addUserDetails = () => {
      loading.value = true;
     const rules:any = inst?.refs.ruleForm;
      if(!rules) return

      const top = ref()

      rules.validate((valid, fields: any) => {
        
            if(valid) {
                CustomerService.addBikeUserDetails(addDetailUserParam.value)
                .then((result: any) => {

                    notif.simple("Customer Added", "success", "Customer has been added successfully!");
                    setTimeout(() => {
                      router.push({ name: "customer-details", params: { customerId: result.data.customer_id } });
                    
                    }, 2000);
                    setTimeout(() => {
                      loading.value = false;
                      reset();
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                    // notif.simple('Customer', 'warning', 'error!')
                    loading.value = false;
                });
            }
            else {
                loading.value = false;
                top.value.focus()

                // focusField()
                // scrollThePage();
            }
        })
    
    };
    
   
    
    const search = ref<string>("");
    const searchCustomers = () => {
      console.log(search.value);
    };

    let countryCodeList = computed(() => {
      return store.getters.getCountryCode;
    });

    const onCountryCodeChange = (event) => {
      addDetailUserParam.value.phone_country_code = event;
    };

    const validateFirstRegistration = computed(() => {
      return new Date(addDetailUserParam.value.first_registration_date);
    });

    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1-$2")
        .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
        .replace(/[^\d-]/g, "");
      nextTick(() => (firstDate.value = result));
    });

    const addFirstRegDate = () => {
      addDetailUserParam.value.first_registration_date = firstDate.value;
    };

    

    const yearMinuseighteen = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear() - 18;
      return new Date(year, month, day);
    });
    const yearMinusOne = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear();
      return new Date(year, month, day);
    });
    const disabledDate = (time: any) => {
      return time.getTime() > new Date(yearMinuseighteen.value);
    };
    const disableDateOne = (time: any) => {
      return time.getTime() < new Date(yearMinusOne.value);
    };

    

    const updateDriverName = () => {
      addDetailUserParam.value.driver_name = addDetailUserParam.value.name;
    };

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    });

    const checkPhoneNo = () => {
      if (addDetailUserParam.value.phone_number) {
        CustomerService.checkExistNumber({
          phoneNo: addDetailUserParam.value.phone_number,
          country: addDetailUserParam.value.phone_country_code,
        });
      }
    };

    const clearIt = () => {
      addDetailUserParam.value.phone_number = "";
      CustomerService.clearExistCustomer();
    };

    const redirectIt = (id: number, redirectType: string) => {
      let path = ''
      if(redirectType === 'customerDetails') path = 'customer-details'
      if(redirectType === 'addCar') path = 'new-car-quote'
      if(redirectType === 'addBike') path = 'customer-add-new-bike'
      if(redirectType === 'addCar') {
        router.push({ name: path, params: { driverDetailsId: id }, query: {leadsAndTasks: 'true'}})
      } else {
        router.push({ name: path, params: { customerId: id }, query: {leadsAndTasks: 'true'}})
      }
      clearIt()
    };

    const checkdateLeadDriverDob = computed(() => {
      return dateDif(addDetailUserParam.value.dob);
    });

    const dateDif = (date: any) => {
      if (date) {
        date = date.split("-");
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var yy = parseInt(date[0]);
        var mm = parseInt(date[1]);
        var dd = parseInt(date[2]);
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
          months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
          years = years - 1;
          months = months + 12;
        }
        // days
        days = Math.floor(
          (today.getTime() -
            new Date(yy + years, mm + months - 1, dd).getTime()) /
            (24 * 60 * 60 * 1000)
        );
        //
        return years;
      }
      return 0;
    };
    const addfixedPosition = () => {
      setBodyPositionFixed.value.isFocused = true;
      console.log("focused");
      document.body.classList.add("body-position-fixed");
    };
    const removeFixedPosition = () => {
      setBodyPositionFixed.value.isFocused = false;
      console.log("removed");
      document.body.classList.remove("body-position-fixed");
    };

    const disableIsNew = computed(() => {
            if(yearList.getYearValue().includes(addDetailUserParam.value.car_year)) {
                return false;
            } else {
                return true;
            }
    })

    
    const currentyear = computed(() => {
      return new Date().getFullYear();
    })

    const dobYear = computed(() => {
      return currentyear.value - 18;
    })

    const currentDay = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const currentMonth = computed(() => {
      const month = new Date().getMonth() + 1;
      return month > 9 ? month : '0'+month;
    })

    const carYearMin = computed(() => {
      const carYear = parseInt(addDetailUserParam.value.car_year);
      return carYear - 2;
    })

    const carYearMax = computed(() => {
      const carYear = parseInt(addDetailUserParam.value.car_year);
      return carYear + 1;
    })

    const amount = ref(0);

    const toExpectPolicyActiveDate = () => {
        const policyActiveRef:any = inst?.refs.policyActiveRef
        policyActiveRef.focus()
    }

    const toExpectPolicyStartDate = () => {
        const expectedPolicyRef:any = inst?.refs.expectedPolicyRef
        expectedPolicyRef.isFocus()
    }

    const toRegisrationEmirate = () => {
        const registrationEmirateRef:any = inst?.refs.registrationEmirateRef
        registrationEmirateRef.focus()
    }

    const toVehicleRegistraion = () => {
        const vehicleUseRef:any = inst?.refs.vehicleUseRef
        vehicleUseRef.focus()
    }

    const toRegistrationDateRef = () => {
        const registrationRef:any = inst?.refs.registrationRef
        if(!registrationRef.disabled) {
          registrationRef.isFocus()
        } else {
          toRegisrationEmirate()
        }
    }

    const toGccSpec = () => {
        const gccSpecRef:any = inst?.refs.gccSpecRef
        gccSpecRef.focus()
    }

    const toDriverNationality = () => {
        const nationalityRef:any = inst?.refs.nationalityRef
        nationalityRef.focus()
    }

    const toGender = () => {
        const genderRef:any = inst?.refs.genderRef
        genderRef.focus()
    }

    const toTPL = () => {
      const tplRef:any = inst?.refs.tplRef
        tplRef.focus()
    }

    const toDob = () => {
      const dobRef:any = inst?.refs.dobRef
      dobRef.isFocus()
    }

    const toDL = () => {
      const dlRef:any = inst?.refs.dlRef
        dlRef.focus()
    }

    const isShow = computed(() => {
      if([1, 7, 11].includes(user.value.role_id)) return true

      return false
    })

    const getAddCustomerEmail = computed(() => {
      // return store.getters.getAddCustomerEmail
      return store.state.CustomerModule.addCustomerEmail
    })

    const getExistCustomer = computed(() => {
      return store.getters.getExistCustomer
    })

    const existingCustomer = ref()

    watchEffect(() => {
      if(getAddCustomerEmail.value) {
        const emailRef:any = inst?.refs.emailRef
        emailRef.focus()
        loading.value = false
      }

      if(getExistCustomer.value) {
        existingCustomer.value.toFirst()
      }
    })

    const fixFigure = () => {
      addDetailUserParam.value.car_value = roundUp(parseFloat(addDetailUserParam.value.car_value.toString().replace(',', '')))
      removeError()
      if(isNaN(addDetailUserParam.value.car_value)) {
        addDetailUserParam.value.car_value = 0
      }
    }

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const policyRestrictDates = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrict
    })
    
    const policyRestrictDatesUpcoming = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrictUpcoming
    })
    const disabledDates = computed(() => {
      console.log(policyRestrictDates.value)
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - policyRestrictDates.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    const disableUpcomingDate = computed(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + policyRestrictDatesUpcoming.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    const toLeadsAndTasks = computed(() => {
      return router.currentRoute.value.query.leadsAndTasks
    }) 

    return {
      carBikeValueCheck,
      toLeadsAndTasks,
      toGender,
      removeError,
      registration,
      getCarYears,
      fixFigure,
      existingCustomer,
      getExistCustomer,
      getAddCustomerEmail,
      user,
      isShow,
      toDL,
      toDob,
      toTPL,
      toRegistrationDateRef,
      toVehicleRegistraion,
      toDriverNationality,
      toGccSpec,
      toExpectPolicyActiveDate,
      toExpectPolicyStartDate,
      toRegisrationEmirate,
      // focusField,
      amount,
      estimatedCarValue,
      getBrandNew,
      carYearMin,
      carYearMax,
      currentyear,
      currentDay,
      currentMonth,
      dobYear,
      disableIsNew,
      yearsList,
      setBodyPositionFixed,
      addfixedPosition,
      removeFixedPosition,
      trimsList,
      modelList,
      manufacturerList,
      checkdateLeadDriverDob,
      checkPhoneNo,
      clearIt,
      redirectIt,
      ElNotification,
      updateDriverName,
      // errorMessage,
      // value,
      disableDateOne,
      yearMinusOne,
      yearMinuseighteen,
      addFirstRegDate,
      firstDate,
      validateFirstRegistration,
      loading,
      matchFirstDL,
      errors,
      disabledDate,
      name,
      email,
      phone_country_code,
      noOfClaims,
      onManufacturerChange,
      addDetailUserParam,
      onModelChange,
      onYearSelect,
      onTrimChange,
      carValue,
      getPolicyExpiry,
      addUserDetails,
      searchCustomers,
      search,
      years,
      // manufacturers,
      // models,
      // trims,
      emirates,
      // nationality,
      countryList,
      // dlCountry,
      dlCountryList,
      uaeExperience,
      homeExperience,
      ncc,
      // salesAgent,
      salesAgentList,
      // countryCodes,
      countryCodeList,
      onCountryCodeChange,
    //   getThirdParty,
      nameRef,
      phoneRef,
      yearRef,
      DobRef,
      //   fetchCarLeadObj,
      validationRules,
      disableUpcomingDate,
      disabledDates,
      otherGenders
    };
  },
});
